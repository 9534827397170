import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/headers/page-header"
import ContentBox from "../components/content-box"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <PageHeader>404: Not Found</PageHeader>
        <ContentBox>
          <p>Not until we are lost do we begin to understand ourselves.</p>
          <small>Henry David Thoreau</small>
        </ContentBox>
      </Layout>
    )
  }
}

export default NotFoundPage
